import React from 'react';
import './Sidebar.css'; // Ensure this file contains the necessary CSS

const Sidebar = ({links, children, events}) => {
    return (
        <div className='sidebar-container'>
            <div className="sidebar">
                {links && links.map((link) => (<a href={link.href} key={link.href}>{link.title}</a>))}
                {events && events.map(event => (<a href={'#'+event.url} className="sublink">{event.title}</a>))}
            </div>
            <div className='sidebar-view-container'>
                <div className='sidebar-view'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
