import React from 'react';
import WsFlow from '../assets/ws-flow.svg'
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import Card from './Card';
import './Setup.css';

const Setup = () => (
  <div className='setup-container'>
    <Card>
      <h1>Setup</h1>
        We are using <a href="https://socket.io/docs/v4/">SocketIO v4.4.0</a> to implement our realtime data syncronisation.
      <h2>Connection Flow</h2>
      <img src={WsFlow} alt="Websocket flow" />
      <p>
          Before establishing a WebSocket connection, it is essential to obtain an authentication token through
          the PMS login endpoint. Upon acquiring your token, you can then initiate a connection to Parklio's WebSocket channels.
          The examples provided below guide you on how to seamlessly connect using the specified namespace 'v2/realtime'.
          Follow these examples to ensure a successful WebSocket connection setup.
      </p>
      <div>
        <SyntaxHighlighter language="typescript" style={oneDark}>
          {`const socket = io("wss://api.parklio.com/v2/realtime}",{
        path: "/socketio",
        query:{
            token: "<token>"
        }
    })`}
        </SyntaxHighlighter>

      </div>
    </Card>
  </div>
);

export default Setup
