import React, { useState, useEffect } from 'react';
import './Changelog.css'; // Your CSS file for styling

const Changelog = () => {
  const [changeLogs, setChangeLogs] = useState([]);

  useEffect(() => {
    // Fetch the JSON file from the public folder
    fetch('data/changelog.json') // Assuming 'updates.json' is in the public directory
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })
      .then(data => setChangeLogs(data))
      .catch(error => console.error('Error fetching JSON:', error));
  }, []);

  return (
    <div className="changelog">
      {changeLogs.map((update, index) => (
        <div key={index} className="update">
          <h2 className="date">{update.date}</h2>
          <ul className="fixes">
            {update.fixes.map((fix, fixIndex) => (
              <li key={fixIndex} className="fix">{fix.value}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Changelog;
