import React, { useState, useEffect, useMemo } from 'react';
import Sidebar from './Sidebar';
import './Websockets.css';
import WebsocketEvent from './WebSocketEvent';
import { useLocation } from 'react-router-dom';
import Setup from './Setup';

const Websockets = () => {
    const [events, setEvents] = useState(null);
    let location = useLocation();

    useEffect(() => {
        fetch('data/events.json') // Assuming 'updates.json' is in the public directory
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Network response was not ok.');
            })
            .then(data => setEvents(data))
            .catch(error => console.error('Error fetching JSON:', error));
    }, []);

    const target = useMemo(() => {
        if (!events) {
            return null;
        }
        return events.filter(event => event.url === location.hash.replace('#', '')).pop()
    }, [events, location])

    return (
        events && <div className='websockets'>
            <Sidebar events={events} links={[{href: "#setup", title: "Setup"}, {href: "#events", title: "Events"}]}>
                {['', '#setup'].includes(location.hash) ? <Setup /> : target && (<WebsocketEvent
                    title={target.title}
                    eventValue={target.eventValue}
                    description={target.description}
                    schema={target.schema}
                    exampleData={target.exampleData}
                />)}
            </Sidebar>
        </div>
    );
};

export default Websockets;

