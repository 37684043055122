import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import ParklioLogo from '../assets/parklio-logo.svg';

const Navbar = () => {
  return (
    <nav className="navbar">
      <a href="/"><img src={ParklioLogo} alt="Description" /></a>
      <div className="nav-links">
        <Link to="/home">Home</Link>
        <Link to="/api">API</Link>
        <Link to="/ws#setup">Websockets</Link>
        <Link to="/changelog">Changelog</Link>
      </div>
    </nav>
  );
};

export default Navbar;
