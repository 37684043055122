import React, { useState } from 'react';
import './WebSocketEvent.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Card from './Card';

const WebsocketEvent = ({ title, eventValue, description, schema, exampleData }) => {
  const [schemaSelected, setSchemaSelected] = useState(true);
  return (
    <div className="api-endpoint">
      <Card>
        <h2>{title}</h2>
        <p>{description}</p>
        <h2>Event Example</h2>
        <div className="code-block">
            <SyntaxHighlighter language="typescript" style={oneDark}>
              {`socket.on("${eventValue}", data => {\n\tconsole.log("Event data received: ", data);\n})`}
            </SyntaxHighlighter>
          </div>
        <h3>Event Payload</h3>
        <div className="tabs">
          <button className={schemaSelected ? 'selected' : ''} onClick={() => setSchemaSelected(true)}>Schema</button>
          <button className={!schemaSelected ? 'selected' : ''} onClick={() => setSchemaSelected(false)}>Example</button>
        </div>
        {schemaSelected ? (
          <div className="code-block">
            <SyntaxHighlighter language="typescript" style={oneDark}>
              {schema}
            </SyntaxHighlighter>
          </div>
        ) : (
          <div className="code-block">
            <SyntaxHighlighter language="json" style={oneDark}>
              {exampleData}
            </SyntaxHighlighter>
          </div>
        )}
      </Card>
    </div>
  );
};

export default WebsocketEvent;
