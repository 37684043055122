import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Api from './components/Api';
import Home from './components/Home';
import Changelog from './components/Changelog'
import Websockets from './components/Websockets';

const App = () => {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/api" element={<Api />} />
        <Route path="/ws" element={<Websockets />} />
        <Route path="/changelog" element={<Changelog />} />
      </Routes>
    </>
  );
};

export default App;